/** @format */

.home-section {
  position: fixed;
  height: 13%;
  /* top: 0; */
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.home-section .circle-1 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 0;
  position: absolute;
  z-index: 9;
  opacity: 100%;
  background-image: linear-gradient(140deg, #ff8700 26%, #e346b4 54%);
  display: flex;
  margin: 0 auto;
  bottom: 35px;
  right: 78px;
}

.home_container {
  width: 100%;
  height: 100vh;
  background-color: #131314;
  position: relative;
}

.home-section .circle-1::before {
  content: "";
  position: absolute;
  transform: translate(0px, -1px);
  z-index: -1;
  background: linear-gradient(325deg, #ff8700, #e142c0, #ff8700, #e142c0);
  background-size: 200% 200%;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  filter: blur(10px);
  animation: gradient 20s linear infinite;
}

/* .home-section .circle-1::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -2px;
    width: 65px;
    height: 65px;
    background: linear-gradient(140deg, #FF8700 26%, #E346B4 54%);
    border-radius: 50%;
    z-index: 0;
    filter: blur(7px);
    top: 0;
    margin: 0 auto;
    right: 0;
} */
.home-section .actions-button {
  position: absolute;
  right: 10px;
  bottom: 104px;
  height: 70px;
  width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.home-section .actions-button button {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 55px;
}

.home-section .actions-button button:last-child {
  margin-right: 0;
}

.home-section .actions-button button:nth-child(1):before {
  content: "";
  position: absolute;
  top: 9px;
  left: 21px;
  width: 50px;
  height: 50px;
  background-color: #1b1b1b;
  border-radius: 50%;
  z-index: 9;
  /* margin: 0 auto; */
  right: 0;
}

.home-section .actions-button button:nth-child(1)::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 9px;
  left: 21px;
  width: 52px;
  height: 52px;
  background: linear-gradient(175deg, #ff8700 17%, #e346b4 49%);
  border-radius: 50%;
  z-index: 0;
  filter: blur(5px);
}
.home-section .actions-button button:nth-child(1):hover::after {
  filter: blur(11px);
}
.home-section .actions-button button:nth-child(2):hover::after {
  filter: blur(11px);
}

.home-section .actions-button button:nth-child(2):before {
  content: "";
  position: absolute;
  top: 10px;
  left: 124px;
  width: 50px;
  height: 50px;
  background-color: #1b1b1b;
  border-radius: 50%;
  z-index: 9;
  /* margin: 0 auto; */
  right: 0;
}

.home-section .actions-button button:nth-child(2)::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 10px;
  left: 124px;
  width: 52px;
  height: 52px;
  background: linear-gradient(175deg, #ff8700 17%, #e346b4 49%);
  border-radius: 50%;
  z-index: 0;
  filter: blur(5px);
}

.home-section .actions-button button img {
  position: relative;
  z-index: 99;
  margin: 10px 0;
}

.home-section .actions-button button:nth-child(1) img {
  width: 22px;
  margin-right: 10px;
}

.home-section .actions-button button:nth-child(2) img {
  width: 30px;
}

/* @media screen and (min-device-width: 1519px) {
    .home-section {
        bottom: 0px;
    }
}
@media screen and (min-device-width: 1366px) {
    .home-section {
        bottom: 50px;
    }
} */

@media only screen and (max-width: 767px) {
  .home-section .circle-1 {
    right: 55px;
  }

  .home-section .actions-button {
    right: -20px;
    position: relative;
    margin: 0 0 0 auto;
    bottom: 90px;
    justify-content: space-evenly;
  }

  .home-section .actions-button button {
    margin: 0 0 0 0;
    padding: 0 0px;
    display: contents;
  }
  .home-section .actions-button button:nth-child(1) img {
    margin-right: 0;
  }

  .home-section .actions-button button:last-child {
    margin: 0 auto;
  }

  .home-section .actions-button button:nth-child(1)::after {
    left: 34px;
  }

  .home-section .actions-button button:nth-child(1):before {
    left: 34px;
  }

  .home-section .actions-button button:nth-child(2):before {
    left: 111px;
  }

  .home-section .actions-button button:nth-child(2):after {
    left: 111px;
  }
}