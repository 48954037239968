/** @format */
.overlayGradient {
  opacity: 0.5;
  height: 100%;
  width: 100%;
}

.overlayGradient::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 1) 100%);
}

.your-charity-content .circle-1 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  z-index: 9;
  opacity: 100%;
  background-image: linear-gradient(140deg, #ff8700, #e142c0);
  display: flex;
  margin: 12px auto;
  left: -7px;
  /* bottom: 35px;
    right: 78px; */
}

.your-charity-content .circle-1::before {
  content: "";
  position: absolute;
  transform: translate(0px, -1px);
  z-index: -1;
  background: linear-gradient(325deg, #ff8700, #e142c0, #ff8700, #e142c0);
  background-size: 200% 200%;
  border-radius: 50%;
  width: 105px;
  height: 105px;
  filter: blur(10px);
  animation: gradient 20s linear infinite;
}

/* .your-charity-content .circle-1::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 105px;
    height: 105px;
    background: linear-gradient(140deg, #FF8700 17%, #E346B4 49%);
    border-radius: 50%;
    z-index: 0;
    filter: blur(11px);
    top: 0;
} */

.landing-modal {
  padding-left: 0 !important;
  z-index: 9999;
  padding-right: 0 !important;
}

.landing-modal .modal-dialog {
  margin: 0rem 0px 0rem auto;
  width: 100%;
  position: relative;
  z-index: 0;
}

.landing-modal .modal-dialog .modal-content {
  background-color: transparent;
  width: 100%;
  height: 100vh;
  border: 0;
  border-radius: 0;
}

.landing-modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.landing-modal .modal-dialog .modal-content .modal-body .full_screen {
  background-color: transparent;
}

.landing-modal .modal-dialog .modal-content .modal-body .full_screen .smallScreenView.chat-bot {
  height: 100vh;
  margin-left: 0;
  width: 100%;
  margin: 0 0 0 auto;
}

.landing-modal .modal-dialog .modal-content .modal-body .full_screen .FullScreenView.chat-bot {
  height: auto;
  width: 1366px;
  margin-left: 0;
  position: absolute;
  left: -54rem;
  right: 0;
}

.chat-modal {
  padding-left: 0 !important;
  z-index: 9999;
  padding-right: 0 !important;
}

.chat-modal .modal-dialog {
  margin: 0rem 0px 0rem auto;
}

.chat-modal .modal-dialog .modal-content {
  background-color: #131314;
  width: 100%;
  height: 100vh;
  border-radius: 0;
  border: 0;
}

.chat-modal .modal-dialog .modal-content .modal-header {
  border-bottom: 0;
  position: relative;
  top: 0px;
  z-index: 9;
  right: 0px;
  /* background: #000000; */
  padding-bottom: 0px;
  width: 100%;
  left: auto;
  align-items: center;
}


.chat-modal .modal-dialog .modal-content .modal-header .gradient-box {
  margin-bottom: 0;
}

.chat-modal .modal-dialog .modal-content .modal-header .gradient-box img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  /* display: none; */
  position: relative;
  z-index: 2;
  cursor: pointer;
  transform: rotate(85deg);
  margin: 18px;
}

.chat-modal .modal-dialog .modal-content .modal-header::after {
  content: '';
  background-image: url(../../images/box_img.png) !important;
  width: 50px;
  height: 50px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: -1;
  right: 11px;
}

.chat-modal .modal-dialog .modal-content .modal-body .option {
  height: 288px;
}

.chat-modal .modal-dialog .modal-content .modal-body .option::before {
  height: 283px;
}

.chat-modal .modal-dialog .modal-content .modal-body .option::after {
  height: 286px;
}

.chat-modal .modal-dialog .modal-content .modal-body .option .action-button a {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.FullScreenView.chat-modal .modal-dialog {
  margin: 0 0 0 auto;
  width: 100%;
  max-width: 100%;
  transition: 1.25s;
}

.smallScreenView.chat-modal .modal-dialog {
  margin: 0 0 0 auto;
  width: 100%;
  max-width: 33%;
  transition: 1.25s;
}

.FullScreenView.landing-modal .modal-dialog {
  margin: 0 0 0 auto;
  width: 100%;
  max-width: 100%;
  transition: 1.25s;
  left: auto;
}


.FullScreenView.landing-modal .modal-dialog .modal-content .modal-body .full_screen .FullScreenView.chat-bot {
  left: auto;
  width: 100%;
  height: 100vh;
}

.smallScreenView.landing-modal .modal-dialog {
  margin: 0 0 0 auto;
  width: 100%;
  max-width: 33%;
  transition: 1.25s;
}

.smallScreenView.chat-modal .modal-dialog .welcome-text {
  display: none;
}

.smallScreenView.chat-modal .modal-dialog .box_scroll {
  display: none;
}

.landing-modal .smallScreenView.chat-bot p {
  left: 0;
}

.FullScreenView.chat-modal .modal-dialog .modal-content .modal-body .typing {
  position: absolute;
  margin-left: 14%;
  top: 83%;
}

.smallScreenView.chat-modal .modal-dialog .modal-content .modal-body .typing {
  margin-left: 68px;
  top: 79%;
}

.chat-modal .modal-dialog .modal-content .modal-header button.btn-close {
  background-image: url(../../images/close_icon.svg) !important;
  width: 17px;
  height: 14px;
  background-size: cover;
  background-size: 13px;
  opacity: 100%;
  box-shadow: none;
  margin-right: 3px;
}

.chat-modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.chat-modal .modal-dialog .modal-content .modal-body .full_screen {
  background-color: transparent;
}

.chat-modal .modal-dialog .modal-content .modal-body .full_screen .smallScreenView.chat-bot {
  height: 100vh;
}

.chat-modal .modal-dialog .modal-content .modal-body .full_screen .FullScreenView.chat-bot {
  height: 100vh;
}

.chat-modal .modal-dialog .modal-content .modal-body .message.incoming::before {
  left: 14px;
  width: 25px;
  height: 25px;
}

.chat-modal .modal-dialog .modal-content .modal-body .message.incoming::after {
  left: 14px;
  width: 25px;
  height: 25px;
}

.chat-modal .modal-dialog .modal-content .modal-body .typing {
  left: auto;
  bottom: 0;
  margin-left: 68px;
}

.modal-backdrop.show {
  opacity: 0 !important;
}

/******************landing css******************/
.full_screen.landing-page {
  height: 100%;
  background-color: #040405;
}

.landing-header {
  text-align: center;
  position: sticky;
  top: 0;
  color: #040405;
  z-index: 9999;
  background-color: #070708;
  width: 100%;
}

.landing-header p {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  background: linear-gradient(90deg, #ffb305 36%, #ff5001 59%) text #ffffff;
  -webkit-text-fill-color: transparent;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  margin-bottom: 0;
  padding: 10px 0;
}

.landing-section .landing-img {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.landing-section .landing-img img {
  width: 1021px;
  height: auto;
}

.landing-footer {
  background: linear-gradient(90deg, #ff8700 38%, #e346b4 65%);
  width: 100%;
  height: 50px;
}

.landing-footer p {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 0;
  color: #fff;
}

.landing-section {
  margin-top: -50px;
}

.landing-footer p strong {
  padding-left: 1px;
  font-weight: 600;
}

.landing-section .contant p {
  font-size: 42px;
  line-height: 50px;
  font-weight: 400;
  padding: 10px 16rem;
  background: linear-gradient(to right,
      #e142c0 0%,
      #ff8700 25%,
      #e142c0 50%,
      #ff8700 75%,
      #e142c0 100%);
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  margin-bottom: 0px;
  font-family: sans-serif;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 20s ease-in-out infinite;
  position: relative;
  top: 52px;
}

.landing-section p.docs {
  text-align: center;
  color: #8d8d8d;
  padding: 0px 20rem 20px;
  margin-bottom: 0;
  margin-top: -47px;
}

.power-penny h1 {
  font-size: 42px;
  line-height: 50px;
  font-weight: 400;
  padding: 10px 22rem;
  background: linear-gradient(to right,
      #e142c0 0%,
      #ff8700 25%,
      #e142c0 50%,
      #ff8700 75%,
      #e142c0 100%);
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  margin-bottom: 20px;
  font-family: sans-serif;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 20s ease-in-out infinite;
}

@keyframes textShine {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 200% 0%;
  }
}

.power-penny .penny-box {
  margin: 0 10rem;
}

.power-penny .penny-box .box-penny {
  display: flex;
  margin-bottom: 10px;
  width: 50%;
  margin-right: 35px;
}

.power-penny .penny-box .box-penny img {
  width: 83px;
  height: 33px;
  margin-top: 10px;
}

.power-penny .penny-box .box-penny .small-content {
  text-align: left;
  padding-left: 12px;
  position: relative;
  z-index: 9;
}

.power-penny .penny-box .box-penny .small-content h3 {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 0;
  font-family: sans-serif;
  color: #ff7602;
}

.power-penny .penny-box .box-penny .small-content p {
  color: #8d8d8d;
  font-size: 14px;
}

.penny-capabilities {
  position: relative;
  top: -22px;
}

.FullScreenView .viewfullscreen {
  position: absolute;
  width: 100%;
  top: 0;
}

.FullScreenView .viewfullscreen p {
  left: 0;
}

.FullScreenView .bottom-icon {
  width: 97%;
}

.penny-capabilities .container {
  position: absolute;
  z-index: 9;
  top: 15px;
  left: 0;
  right: 0;
}

.your-charity-section {
  position: relative;
  margin: -45px 0;
  top: -51px;
}

.your-charity-section .container {
  position: absolute;
  z-index: 9;
  top: 72px;
  left: 0;
  right: 0;
}

.penny-capabilities h1 {
  font-size: 42px;
  line-height: 50px;
  font-weight: 400;
  padding: 36px 9rem;
  /* background: linear-gradient(90deg, #fdaf05 38%, #ff7602 52%) text #ffffff; */
  background: linear-gradient(to right,
      #fdaf05 0%,
      #ff7602 10%,
      #fdaf05 20%,
      #ff7602 40%,
      #fdaf05 50%,
      #ff7602 60%,
      #fdaf05 80%,
      #ff7602 90%,
      #fdaf05 100%);
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  margin-bottom: 20px;
  font-family: sans-serif;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 20s ease-in-out infinite;
}

.penny-capabilities .penny-box {
  margin: 0 10rem;
}

.penny-capabilities .penny-box .box-penny {
  display: flex;
  margin-bottom: 22px;
  width: 50%;
  margin-right: 22px;
}

.penny-capabilities .penny-box .box-penny img {
  width: 50px;
  height: 31px;
  margin-top: 10px;
}

.penny-capabilities .penny-box .box-penny .small-content {
  text-align: left;
  padding-left: 20px;
}

.penny-capabilities .penny-box .box-penny .small-content h3 {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 0;
  font-family: sans-serif;
  color: #fdaf05;
}

.penny-capabilities .penny-box .box-penny .small-content p {
  color: #8d8d8d;
  font-size: 14px;
}

.charity-tab {
  padding-top: 0px;
  margin: -231px 10rem 33px;
  position: relative;
  z-index: 9;
}

.charity-tab .ml-5 {
  padding-left: 0rem;
}

.charity-tab .charity-img {
  position: relative;
  top: 18px;
}

.charity-tab .charity-img img {
  width: 77%;
  position: relative;
  z-index: 9;
  height: 633px;
}

.charity-tab .charity-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 77%;
  height: 100%;
  background: linear-gradient(0deg, #ff8700 17%, #e346b4 49%);
  border-radius: 12px;
  background-size: 200% 200%;
  z-index: 0;
  filter: blur(14px);
  animation: animateBorder1 10s linear infinite;
}

@keyframes animateBorder1 {
  0% {
    background-position: 0% 50%;
    background: linear-gradient(0deg, #ff8700 17%, #e346b4 49%);
  }

  50% {
    background-position: 100% 50%;
    background: linear-gradient(180deg, #ff8700 17%, #e346b4 49%);
  }

  100% {
    background-position: 0% 50%;
    background: linear-gradient(360deg, #ff8700 17%, #e346b4 49%);
  }
}

.charity-tab .charity-content h2 {
  font-size: 42px;
  line-height: 50px;
  font-weight: 400;
  padding: 10px 9rem 10px 0;
  background: linear-gradient(to right,
      #e142c0 0%,
      #ff8700 25%,
      #e142c0 50%,
      #ff8700 75%,
      #e142c0 100%);
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  margin-bottom: 20px;
  font-family: sans-serif;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 20s ease-in-out infinite;
}

.charity-tab .charity-desktop .charity-box {
  background-color: #1e1f20;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  margin-right: 0px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  /* border-image: linear-gradient(180deg, #FF8700 0%, #E346B4 100%) 1; */
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
}

.charity-tab .charity-desktop .charity-box::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 100%;
  height: 106px;
  background-color: #1e1f20;
  border-radius: 12px;
  z-index: 9;
  margin: 0 auto;
  right: 0;
}

.charity-tab .charity-desktop .charity-box::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ff8700 17%, #e346b4 49%);
  border-radius: 12px;
  z-index: 0;
  filter: blur(3px);
}

.charity-tab .charity-desktop .charity-box img {
  position: relative;
  z-index: 99;
  width: 50px;
  height: 37px;
}

.charity-tab .charity-desktop .charity-box .small-content {
  position: relative;
  z-index: 99;
  text-align: left;
  padding-left: 20px;
}

.charity-tab .charity-desktop .charity-box .small-content h3 {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 0;
  font-family: sans-serif;
  color: #d37306;
}

.charity-tab .charity-desktop .charity-box .small-content p {
  font-size: 14px;
  color: #8d8d8d;
  margin-bottom: 0;
}

.your-charity {
  margin: 0 10rem;
  display: flex;
}

.your-charity .your-charity-content {
  padding-top: 60px;
}

.your-charity .your-charity-content h3 {
  font-size: 35px;
  line-height: 50px;
  font-weight: 400;
  padding: 0px 0rem 10px 0;
  background: linear-gradient(to right,
      #e142c0 0%,
      #ff8700 25%,
      #e142c0 50%,
      #ff8700 75%,
      #e142c0 100%);
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  margin-bottom: 0px;
  font-family: sans-serif;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 20s ease-in-out infinite;
  text-align: center;
}

.penny-tittle.chat-penny svg {
  margin: 0px 6rem 16px;
}

.your-charity .your-charity-content h1 {
  font-size: 37px;
  line-height: 50px;
  font-weight: 400;
  padding: 10px 0rem 10px 0;
  background: linear-gradient(to right,
      #e142c0 0%,
      #ff8700 25%,
      #e142c0 50%,
      #ff8700 75%,
      #e142c0 100%);
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  margin-bottom: 0px;
  font-family: sans-serif;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 20s ease-in-out infinite;
}

.your-charity .your-charity-content p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #8d8d8d;
  margin-bottom: 20px;
  font-family: sans-serif;
  width: 382px;
}

.your-charity .your-img {
  width: 100%;
  padding-top: 5rem;
  position: relative;
}

.your-charity .your-img img {
  width: 97%;
  margin-left: 15px;
}

.your-charity .your-img .box_showds {
  width: 98%;
  background: linear-gradient(180deg, #ff8700 17%, #e346b4 49%);
  border-radius: 12px;
  z-index: -1;
  filter: blur(8px);
  height: 348px;
  position: absolute;
  top: 80px;
  margin: 0 auto;
  left: 11px;
  right: 0;
}

.FullScreenView .penny-tittle.chat-penny svg {
  margin: 0px 12rem 16px;
}

.smallScreenView .penny-tittle.chat-penny {
  width: 100%;
}

.smallScreenView .penny-tittle.chat-penny svg {
  margin: 0px auto 16px;
  width: 100%;
}

.penny-tittle svg {
  fill: #ff8700;
  animation: fillChange 2s ease-in-out infinite alternate;
}

@keyframes fillChange {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* .penny-tittle path {
  transform: translateY(25px) skewY(20deg);
} */

/* .FullScreenView.chat-modal .modal-dialog .modal-content .modal-body .typing {
  margin-left: 13rem;
  top: 83%;
}

.smallScreenView.chat-modal .modal-dialog .modal-content .modal-body .typing {
  margin-left: 64px;
  top: 80%;
} */

.landing-modal p.transcript-text {
  width: 44%;
  margin: 0 auto;
  overflow: hidden;
  height: 56px;
  max-height: 56px;
  text-align: center;
}

@media screen and (max-device-width: 1519px) {
  .charity-tab .charity-content h2 {
    padding: 10px 6rem 10px 0;
  }

  .charity-tab {
    margin: -100px 6rem 33px;
  }

  .your-charity {
    margin: 0 6rem;
  }

  .power-penny .penny-box {
    margin: 0 6rem;
  }

  .penny-capabilities .penny-box {
    margin: 0 6rem;
  }
}

@media screen and (max-device-width: 1366px) {
  .charity-tab .charity-content h2 {
    padding: 10px 4rem 10px 0;
  }

  .charity-tab {
    margin: -100px 4rem 33px;
  }

  .your-charity {
    margin: 0 4rem;
  }

  .power-penny .penny-box {
    margin: 0 4rem;
  }

  .penny-capabilities .penny-box {
    margin: 0 4rem;
  }

  .FullScreenView.chat-modal .option::before {
    width: 229px;
  }

  .landing-section .contant p {
    padding: 10px 14rem;
  }

  .smallScreenView .penny-tittle.chat-penny svg {
    margin: 0px auto 16px;
    width: 100%;
  }

  .smallScreenView.chat-modal .modal-dialog .modal-content .modal-body .typing {
    top: 79%;
  }

  .FullScreenView.chat-modal .modal-dialog .modal-content .modal-body .typing {
    top: 82%;
  }
}

@media only screen and (max-width: 767px) {
  .landing-section .contant p {
    padding: 10px 1rem;
    margin-bottom: 50px;
    font-size: 39px;
  }

  .FullScreenView .penny-tittle.chat-penny svg {
    margin: 0px 0rem 5px;
  }

  .smallScreenView .penny-tittle.chat-penny svg {
    margin: 0px 0rem 5px;
  }

  .chat-modal .modal-dialog .modal-content .modal-header {
    padding-bottom: 20px;
  }

  .FullScreenView .penny-tittle svg {
    margin: 15px 1rem;
  }

  .penny-tittle.chat-penny svg {
    margin: 0px 0rem 16px;
  }

  .FullScreenView .content-tittle {
    padding: 0 30px 4px 1rem;
  }

  .smallScreenView.chat-modal .modal-dialog {
    max-width: 100%;
  }

  .smallScreenView.landing-modal .modal-dialog {
    max-width: 100%;
  }

  .your-charity .your-charity-content h3 {
    text-align: center;
  }

  .landing-section .contant span {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    padding: 10px 0rem;
    background: linear-gradient(90deg, #ff8700 32%, #e346b4 78%) text #ffffff;
    -webkit-text-fill-color: transparent;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    letter-spacing: normal;
    margin-bottom: 0;
    font-family: sans-serif;
  }

  .landing-section p.docs {
    padding: 0px 0rem 20px;
  }

  .contant.text-center {
    position: relative;
    top: -71px;
    margin-top: -46px;
  }

  .power-penny h1 {
    padding: 10px 1rem;
    text-align: center;
    background: linear-gradient(90deg, #ff8700 28%, #e346b4 87%) text #ffffff;
    font-size: 37px;
    line-height: 42px;
  }

  .penny-capabilities .penny-box .box-penny img {
    margin-top: 0;
    position: relative;
    z-index: 9;
  }

  .penny-capabilities .penny-box .box-penny .small-content {
    position: relative;
    z-index: 9;
    padding-left: 14px;
  }

  .swiper .swiper-wrapper {
    margin: 0 10px;
  }

  .swiper .swiper-wrapper .swiper-slide .box-penny {
    background-color: #1e1f20;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    margin-right: 0px;
    margin-bottom: 20px;
    width: 96%;
    height: 260px;
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    margin-top: 7px;
    flex-direction: column;
  }

  .swiper-pagination {
    position: relative !important;
  }

  .swiper-pagination-bullet {
    border: 2px solid #ff8700 !important;
    width: 12px !important;
    height: 12px !important;
    opacity: 100% !important;
  }

  .swiper-pagination-bullet-active {
    width: 12px !important;
    height: 12px !important;
    background: linear-gradient(180deg, #ff8700 17%, #e346b4 49%) !important;
    filter: blur(1px) !important;
  }

  .swiper .swiper-wrapper .swiper-slide .box-penny img {
    position: relative;
    z-index: 99;
    margin-bottom: 14px;
  }

  .swiper .swiper-wrapper .swiper-slide .box-penny .small-content {
    position: relative;
    z-index: 99;
  }

  .swiper .swiper-wrapper .swiper-slide .box-penny .small-content p {
    color: #8d8d8d;
  }

  .swiper .swiper-wrapper .swiper-slide .box-penny::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 0;
    width: 98%;
    height: 97%;
    background-color: #1e1f20;
    border-radius: 12px;
    z-index: 9;
    margin: 0 auto;
    right: 0;
  }

  .swiper .swiper-wrapper .swiper-slide .box-penny::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ff8700 17%, #e346b4 49%);
    border-radius: 12px;
    z-index: 0;
    filter: blur(3px);
  }

  .swiper .swiper-wrapper .swiper-slide .box-penny .small-content h3 {
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 0;
    font-family: sans-serif;
    color: #ff7602;
  }

  .penny-capabilities .penny-box {
    margin: 0px -12px;
    overflow: auto;
    display: flex;
    padding: 0 15px;
  }

  .penny-capabilities .penny-box::-webkit-scrollbar {
    display: none;
  }

  .penny-capabilities {
    top: 35px;
  }

  .penny-capabilities .container {
    top: 0;
  }

  .penny-capabilities .penny-box .box-penny {
    width: 342px;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    padding: 20px 11px;
    margin-right: 16px;
  }

  .penny-capabilities .penny-box .box-penny .small-content h3 {
    font-size: 14px;
  }

  .penny-capabilities .penny-box .box-penny::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    width: 98%;
    height: 113px;
    background-color: #1e1f20;
    border-radius: 12px;
    z-index: 9;
    margin: 0 auto;
    right: 0;
  }

  .penny-capabilities .penny-box .box-penny .small-content p {
    margin-bottom: 0;
    font-size: 12px;
  }

  .penny-capabilities .penny-box .box-penny::after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 3px;
    left: 0;
    width: 100%;
    height: 120px;
    background: linear-gradient(180deg, #ff8700 17%, #e346b4 49%);
    border-radius: 12px;
    z-index: 0;
    filter: blur(3px);
  }

  .penny-capabilities h1 {
    padding: 0px 0 20px;
    font-size: 37px;
    line-height: 42px;
    background: linear-gradient(90deg, #ff8700 10%, #e346b4 100%) text #ffffff;
  }

  .your-charity .your-charity-content img {
    width: 100%;
    position: relative;
    z-index: 9 !important;
  }

  .your-charity .your-charity-content .box_showds {
    width: 77%;
    background: linear-gradient(180deg, #ff8700 17%, #e346b4 49%);
    border-radius: 12px;
    z-index: -1;
    filter: blur(20px);
    height: 206px;
    position: absolute;
    top: 204px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .your-charity .your-charity-content p {
    width: 100%;
    text-align: center;
  }

  .charity-tab {
    margin: 40px 0rem 33px;
  }

  .charity-tab .charity-img img {
    height: auto;
    width: 100%;
  }

  .charity-tab .charity-img::after {
    width: 100%;
    filter: blur(15px);
  }

  .your-charity {
    margin: 0;
  }

  .your-charity .your-charity-content {
    width: 100%;
    padding-top: 0;
  }

  .your-charity-section .container {
    position: relative;
    top: 0;
  }

  .your-charity-content .circle-1 {
    margin: 0px auto;
  }

  .your-charity .your-charity-content h1 {
    text-align: center;
  }

  .your-charity-section {
    margin: 0px 0;
    top: 0px;
  }

  .charity-tab .charity-content h2 {
    text-align: center;
    padding: 10px 0rem 30px 0;
    font-size: 36px;
    line-height: 42px;
    background: linear-gradient(90deg, #ff8700 10%, #e346b4 100%) text #ffffff;
  }

  .charity-tab .ml-5 {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .charity-tab .charity-img {
    top: 0;
    margin: 0 15px;
  }

  .faq-section .Collapsible.Collapsible .Collapsible__trigger.is-closed::after {
    transform: rotate(270deg);
    transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    background-image: url(../../images/arrow_icon.svg);
    height: 8px;
    top: 34px;
    width: 14px;
    display: flex;
    content: "";
    position: absolute;
    right: 0;
  }

  .landing-section .landing-img img {
    width: 100%;
  }

  .faq-section .Collapsible.Collapsible .Collapsible__trigger.is-open::after {
    transform: rotate(363deg);
    transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    background-image: url(../../images/arrow_icon.svg);
    height: 8px;
    top: 34px;
    width: 14px;
    display: flex;
    content: "";
    position: absolute;
    right: 0;
  }

  .faq-section .Collapsible:nth-child(1).Collapsible .Collapsible__trigger.is-closed:before {
    background-image: url(../../images/social_icon9.svg);
    background-size: 26px;
    content: "";
    height: 26px;
    left: 0;
    position: absolute;
    top: 25px;
    width: 26px;
  }

  .faq-section .Collapsible:nth-child(1).Collapsible .Collapsible__trigger.is-open:before {
    background-image: url(../../images/social_icon9.svg);
    background-size: 26px;
    content: "";
    height: 26px;
    left: 0;
    position: absolute;
    top: 25px;
    width: 26px;
  }

  .faq-section .Collapsible:nth-child(2).Collapsible .Collapsible__trigger.is-closed:before {
    background-image: url(../../images/social_icon10.svg);
    background-size: 22px;
    content: "";
    height: 19px;
    left: 0;
    position: absolute;
    top: 31px;
    width: 22px;
  }

  .faq-section .Collapsible:nth-child(2).Collapsible .Collapsible__trigger.is-open:before {
    background-image: url(../../images/social_icon10.svg);
    background-size: 22px;
    content: "";
    height: 19px;
    left: 0;
    position: absolute;
    top: 31px;
    width: 22px;
  }

  .faq-section .Collapsible:nth-child(3).Collapsible .Collapsible__trigger.is-closed:before {
    background-image: url(../../images/social_icon11.svg);
    background-size: 21px;
    content: "";
    height: 25px;
    left: 0;
    position: absolute;
    top: 27px;
    width: 21px;
  }

  .faq-section .Collapsible:nth-child(3).Collapsible .Collapsible__trigger.is-open:before {
    background-image: url(../../images/social_icon11.svg);
    background-size: 21px;
    content: "";
    height: 25px;
    left: 0;
    position: absolute;
    top: 27px;
    width: 21px;
  }

  .faq-section .Collapsible:nth-child(4).Collapsible .Collapsible__trigger.is-closed:before {
    background-image: url(../../images/social_icon12.svg);
    background-size: 18px;
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: 24px;
    width: 18px;
  }

  .faq-section .Collapsible:nth-child(4).Collapsible .Collapsible__trigger.is-open:before {
    background-image: url(../../images/social_icon12.svg);
    background-size: 18px;
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: 24px;
    width: 18px;
  }

  .faq-section .Collapsible .Collapsible__trigger {
    color: #ff8700;
    font-size: 18px;
    padding: 0px 26px 0rem 37px;
  }

  .faq-section .Collapsible .Collapsible__contentInner p {
    color: #8d8d8d;
    font-size: 13px;
    padding-top: 15px;
    margin-bottom: 2px;
  }

  .faq-section {
    margin: 20px 15px 0;
  }

  .faq-section .Collapsible {
    position: relative;
    padding: 24px 0;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(0.1turn, #ff8700, #e142c0);
    border-image-slice: 1;
    width: 100%;
  }
}