/* src/components/Home.css */

.home-container {
  background-color: #131314;
  color: #fff;
  height: 84vh;
  position: relative;
}

.home-container .bottom-icon {
  padding-top: 10px;
}

.home-container .bottom-icon.chat-close {
  padding-bottom: 5px;
}

.bottom-icon {
  display: flex;
  justify-content: end;
  flex-direction: row;
  width: 100%;
  padding-bottom: 0;
  padding-top: 7px;
}

.bottom-icon button {
  background-color: transparent;
  cursor: pointer;
  border: 0;
}

.welcome-text {
  padding: 0 6rem;
}

.welcome-text h1 {
  font-size: 24px;
  background: linear-gradient(90deg, #ff8700 3%, #e142c0 14%) text #ffffff;
  -webkit-text-fill-color: transparent;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  font-weight: 600;
  margin: 0;
  background: linear-gradient(to right,
      #ff8700 10%,
      #e142c0 20%,
      #ff8700 40%,
      #e142c0 60%,
      #ff8700 80%,
      #e142c0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 30s ease-in-out infinite alternate;
}

.welcome-text h2 {
  color: #8d8d8d;
  margin: 0;
  font-weight: normal;
  font-size: 40px;
  margin-bottom: 50px;
}

.bg_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.options-container {
  display: flex;
  justify-content: center;
  width: 87%;
  margin: 0 auto 60px;
}

.option {
  background-color: #1b1b1b;
  border-radius: 20px;
  padding: 0px;
  text-align: center;
  margin-right: 22px;
  width: 259px;
  height: 300px;
  position: relative;
  z-index: 9;
}

.option img {
  width: 100%;
  position: relative;
  z-index: 9;
  margin: 3px auto 0;
  height: 148px;
  border-radius: 20px 20px 0 0;
}

.option .option-content {
  padding: 15px 20px;
}

.option .option-content p.desc {
  height: 44px !important;
  overflow: hidden;
  align-items: flex-start;
}

.option::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 258px;
  height: 298px;
  background-color: #1b1b1b;
  border-radius: 20px;
  z-index: 9;
  margin: 0 auto;
  right: 0;
  overflow: hidden;
}

.option::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 298px;
  background: linear-gradient(180deg, #ff8700 17%, #e346b4 49%);
  border-radius: 20px;
  z-index: 0;
  filter: blur(3px);
}

.option:last-child {
  margin-right: 0;
}

.option h3 {
  color: #ff8700;
  margin-bottom: 0px;
  font-size: 21px;
  text-align: left;
  position: relative;
  z-index: 9;
}

.desc {
  margin-top: -6px;
  padding: 10px 0;
  color: #8d8d8d;
  text-align: left;
  overflow: hidden;
  max-height: 133px;
  height: 133px;
  position: relative;
  z-index: 9;
}

.action-button {
  background: linear-gradient(90deg, #ff8700 -6%, #e346b4 44%) !important;
  border: none;
  color: white;
  width: 215px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.message-input::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 98.4%;
  height: 50px;
  background-color: #1b1b1b;
  border-radius: 10px;
  z-index: 9;
  margin: 0 auto;
  right: 0;
}

.message-input::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 98.7%;
  height: 54px;
  background: linear-gradient(180deg, #ff8700 17%, #e346b4 49%);
  border-radius: 10px;
  z-index: 0;
  right: 0;
  margin: 0 auto;
  filter: blur(3px);
}

.message-input {
  display: flex;
  align-items: center;
  width: 75%;
  margin: 50px auto;
  position: absolute;
  height: 50px;
  margin-bottom: 1px;
  z-index: 9;
  bottom: 21px;
  justify-content: center;
  left: 0;
  right: 0;
}

button.mic-icon {
  background-color: transparent;
  border: 0;
  position: absolute;
  z-index: 9;
  right: -38px;
}

button.mic-icon img {
  width: 20px;
  height: 25px;
}

.message-input input {
  flex: 1;
  padding: 10px 60px 10px 22px;
  border-radius: 10px;
  border: none;
  margin-right: 10px;
  background-color: transparent;
  color: white;
  height: 50px;
  z-index: 9;
  position: relative;
  outline: none;
}

.disabled {
  background: gray;
  border: none;
  color: white;
  font-size: 24px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  padding: 0;
}

.disabled img {
  width: 14px;
  height: 14px;
}

.send-button {
  background: linear-gradient(148deg, #ff8700 34%, #e346b4 57%);
  border: none;
  color: white;
  font-size: 24px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  padding: 0;
}

.send-button img {
  width: 14px;
  height: 14px;
}

.chat-container {
  background-color: #1e1f20;
  height: 68vh;
  overflow: auto;
  width: 90%;
  margin: 0 auto;
}

.chat-container::-webkit-scrollbar {
  display: none;
}

.chat-container .messages {
  background-color: #131314;
  width: 100%;
  padding: 0 20px;
  position: relative;
  height: 100%;
  padding-top: 16px;
  overflow-y: auto;
  scrollbar-width: auto;
  bottom: 0;
  clear: both;
}

.chat-container .messages::-webkit-scrollbar {
  display: none;
}

.message.incoming {
  width: 93%;
  margin: 0 0 0 auto;
  display: flex;
  justify-content: space-between;
}

.message.incoming::after {
  content: "";
  position: absolute;
  left: 20px;
  width: 36px;
  height: 36px;
  background: linear-gradient(148deg, #ff8700 21%, #e346b4 61%);
  border-radius: 20px;
  z-index: 0;
  filter: blur(0px);
}

.message.incoming::before {
  content: "";
  position: absolute;
  left: 20px;
  width: 36px;
  height: 36px;
  background: linear-gradient(148deg, #ff8700 21%, #e346b4 61%);
  border-radius: 20px;
  z-index: 0;
  filter: blur(6px);
}

.message.incoming .message-content {
  background-color: #000;
  color: white;
  border-radius: 15px;
  padding: 19px 18px;
  margin-bottom: 15px;
  border: 1px solid #ff8700;
  height: auto;
  width: 100%;
}

.message.outgoing .message-content p {
  margin-bottom: 0;
}

.message.incoming .message-content p {
  display: inline-block;
  white-space: pre-wrap !important;
  overflow: hidden;
  margin-bottom: 0;
  width: 100%;
}

.message.incoming:nth-child(1) .message-content p {
  animation: none;
}

@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

.typing .ball {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: wave 1.5s ease-in-out infinite;
  background-color: #fff;
  margin-right: 3px;
}

.typing .ball:nth-child(2) {
  animation-delay: -0.2s;
  background-color: #fff;
}

.typing .ball:nth-child(3) {
  animation-delay: -0.4s;
  background-color: #fff;
}

@keyframes wave {
  25% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.5em);
  }

  75% {
    transform: translateY(0);
  }
}

.message.outgoing {
  width: 50%;
  margin: 0 0 0 auto;
}

.message.outgoing .message-content {
  background-color: #1e1f20;
  color: white;
  border-radius: 15px;
  padding: 19px 18px;
  margin-bottom: 15px;
  height: auto;
}

.typing {
  position: fixed;
  top: 84%;
  left: 14%;
  display: flex;
}

/* @media only screen and (max-device-width: 1366px) {
  .options-container.speak {
    width: 80%;
  }
} */

/* @media only screen and (max-device-width: 1519px) {
    .home-container .option::before {
        width: 257px;
    }
} */


@media only screen and (max-width: 767px) {
  .welcome-text {
    padding: 0 0rem;
  }

  .home-container {
    height: 100%;
  }

  .home-container .bottom-icon {
    width: 100%;
  }

  .disabled {
    right: 14px;
  }

  button.mic-icon {
    right: -50px;
  }

  .welcome-text h1 {
    background: linear-gradient(90deg, #ff8700 23%, #e142c0 36%) text #ffffff;
  }

  .chat-modal .modal-dialog .modal-content .modal-body .typing {
    left: auto;
    margin-left: 29px !important;
    top: 86%;
    /* position: absolute; */
  }

  .welcome-text h2 {
    font-size: 26px;
  }

  .options-container {
    width: 971px;
    overflow: auto;
  }

  .options-container::-webkit-scrollbar {
    display: none;
  }

  .box_scroll {
    width: 100%;
    overflow: auto;
  }

  .box_scroll::-webkit-scrollbar {
    display: none;
  }

  .message-input {
    width: 80%;
    margin: 0 auto 0px 24px;
    bottom: 22px;
  }

  .send-button {
    right: 14px;
  }

  .message.incoming {
    width: 93%;
  }

  .chat-container {
    width: 100%;
    height: 76vh;
  }

  .message.outgoing {
    width: 70%;
  }

  .message.incoming::before {
    left: 6px !important;
    width: 24px;
    height: 24px;
  }

  .message.incoming::after {
    left: 6px !important;
    width: 24px;
    height: 24px;
  }

  /* .chat-container .messages {
    height: 169vw;
  } */

  /* .chat-container {
    height: 100%;
  } */
}

@media only screen and (max-device-width: 390px) {
  .chat-modal .modal-dialog .modal-content .modal-body .typing {
    top: 88%;
  }
}

@media only screen and (max-device-width: 375px) {
  .chat-modal .modal-dialog .modal-content .modal-body .typing {
    top: 86%;
  }
}