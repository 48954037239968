/**
 * .full_screen {
 *     background-color: #131314;
 *     width: 100%;
 *     height: 695px;
 * }
 * 
 * .FullScreenView.chat-bot {
 *     background-color: #1e1f20;
 *     height: 695px;
 *     box-shadow: -25px 0px 25px #00000080;
 *     transition: 1.25s;
 *     width: 100%;
 * }
 * 
 * .smallScreenView.chat-bot {
 *     background-color: #1e1f20;
 *     height: 695px;
 *     width: 30%;
 *     box-shadow: -25px 0px 25px #00000080;
 *     transition: 1.25s;
 *     position: sticky;
 *     right: 0;
 *     margin-left: 70%;
 * }
 * 
 * .FullScreenView .penny-tittle {
 *     color: #fd830a;
 *     margin: 0;
 *     font-size: 18px;
 *     font-weight: 300;
 *     padding: 10px 10rem;
 * }
 * 
 * .smallScreenView .penny-tittle {
 *     padding: 10px 26px 0;
 *     color: #fd830a;
 *     margin: 0;
 *     font-size: 18px;
 *     font-weight: 300;
 * }
 * 
 * .FullScreenView .box_scroll.speak {
 *     display: block;
 * }
 * 
 * .smallScreenView .box_scroll.speak {
 *     display: none;
 * }
 * 
 * .option.speak {
 *     width: 170px;
 *     height: 170px;
 *    
 * }
 * 
 * .option.speak::before {
 *     width: 167px;
 *     height: 164px;
 * }
 * 
 * .option.speak::after {
 *     height: 170px;
 * }
 * 
 * .option.speak .action-button {
 *     width: 77%;
 *     height: 41px;
 *     font-size: 14px;
 *     margin: 0 auto;
 *     display: flex;
 *     justify-content: center;
 *     left: 0;
 *     background-image: none;
 *     right: 0;
 *     background: linear-gradient(106deg, #FF8700 3%, #E346B4 59%, #E346B4 92%);
 *     border-radius: 10px;
 * }
 * 
 * .option.speak h3 {
 *     font-size: 13px;
 * }
 * 
 * .option.speak p.desc {
 *     max-height: auto;
 *     height: auto;
 *     left: 0;
 *     justify-content: flex-start;
 *     font-size: 12px;
 * }
 * 
 * .options-container.speak {
 *     width: 100%;
 *     margin: 34px auto 0;
 * }
 * 
 * .chat-bot p {
 *     position: relative;
 *     display: flex;
 *     left: 26px;
 *     right: 0;
 *     justify-content: center;
 *     align-items: center;
 *     margin: 0;
 *    
 *     top: 2px;
 *     color: #8D8D8D;
 * }
 * 
 * .demo {
 *     min-width: 150px;
 *     position: relative;
 * }
 * 
 * .circles {
 *     opacity: 0.3;
 *     border-radius: 50%;
 * }
 * 
 *     }
 * 
 *     50% {
 *         transform: scale(1.1);
 *     }
 * }
 * 
 * 
 * 
 * 
 * 
 * .gradient-box {
 *     position: relative;
 *     width: 0px;
 *     height: 50px;
 *    
 *     z-index: 99;
 *    
 * }
 * 
 * .gradient-box::before {
 *     content: '';
 *     position: absolute;
 *     background-image: url(../../images/box_img.png);
 *     width: 50px;
 *     height: 50px;
 *     background-size: cover;
 *     cursor: pointer;
 * }
 * 
 * .chat-bot p img {
 *     width: 14px;
 *     
 *     position: relative;
 *     z-index: 2;
 *     cursor: pointer;
 *     transform: rotate(85deg);
 *     margin: 9px;
 * }
 * 
 * .chat-bot p img.FullScreenView {
 *     width: 16px;
 * }
 * 
 * .chat-bot img.bg_img {
 *     width: 55px;
 *     height: 55px;
 * }
 * 
 * .FullScreenView .content-tittle {
 *     width: 100%;
 *     padding: 0 30px 4px 10rem;
 *     position: absolute;
 *     top: 53px;
 * }
 * 
 * .smallScreenView .content-tittle {
 *     width: 100%;
 *     padding: 0 30px 50px 2rem;
 *     position: relative;
 *     top: 0px;
 * }
 * 
 * .content-tittle h2 {
 *     font-size: 24px;
 *     background: linear-gradient(90deg, #FF8700 3%, #E142C0 9%) text #ffffff;
 *     -webkit-text-fill-color: transparent;
 *     font-variant-ligatures: normal;
 *     font-variant-caps: normal;
 *     letter-spacing: normal;
 *     font-weight: 400;
 *     margin: 0;
 * }
 * 
 * .content-tittle p {
 *     justify-content: flex-start;
 *     position: relative;
 *     left: 0;
 *     height: auto;
 *     color: #8D8D8D;
 *     font-size: 24px;
 *     margin-top: 2px;
 *     top: 0;
 * }
 * 
 * .circle {
 *     border-radius: 50%;
 *     width: 150px;
 *     height: 150px;
 *     cursor: pointer;
 *     margin-bottom: 0;
 *     position: relative;
 *     z-index: 9;
 *     background-image: linear-gradient(325deg, #FF8700 26%, #E142C0 54%);
 *    
 * 
 * }
 * 
 *     }
 * 
 *     20% {
 *         z-index: -9999;
 *         opacity: 0;
 *     }
 * 
 *     50% {
 *         transform: translate(3px, 0);
 *     }
 * 
 *     100% {
 *         transform: translate(-4px, 0);
 *     }
 * }
 * 
 * .circle::after {
 *     content: "";
 *     position: absolute;
 *     transform: translate(0px, -1px);
 *     z-index: 0;
 *     background-image: linear-gradient(325deg, #FF8700, #E142C0);
 *     background: linear-gradient(325deg, #FF8700, #E142C0);
 *     border-radius: 50%;
 *     width: 150px;
 *     height: 150px;
 *     filter: blur(5px);
 *     z-index: 1;
 * 
 * 
 * }
 * 
 * .bottom-icon {
 *     width: 97%;
 *     padding-bottom: 0px;
 * }
 * 
 * .bottom-icon button {
 *     background-image: url(../../images/box_img.png);
 *     width: 50px;
 *     height: 50px;
 *     background-size: cover;
 *     display: flex;
 *     align-items: center;
 *     justify-content: center;
 * }
 * 
 * 
 * 
 * .bottom-icon button img {
 *     width: 12px;
 *     height: 12px;
 * }
 * 
 * 
 * 
 * 
 *     }
 * 
 *     .bottom-icon {
 *         width: 95%;
 *         margin: 0 auto;
 *     }
 * 
 *    
 *     .smallScreenView.chat-bot {
 *         margin-left: 0;
 *         width: 100%;
 *     }
 * 
 *     .content-tittle h2 {
 *         background: linear-gradient(90deg, #FF8700 -10%, #E142C0 32%) text #ffffff;
 *     }
 * 
 *     .FullScreenView .box_scroll.speak {
 *         display: none;
 *     }
 * 
 *     .FullScreenView .content-tittle {
 *         width: 100%;
 *         padding: 0 30px 50px 2rem;
 *         position: relative;
 *         top: 0px;
 *     }
 *     .FullScreenView .penny-tittle {
 *         padding: 10px 26px 0;
 *     }
 * }
 * 
 *     }
 * 
 *     100% {
 *         background-position: -80% -100%;
 *     }
 * }
 * 
 *     }
 * 
 *     100% {
 *         transform: rotate(-366deg);
 *     }
 * }
 * 
 * .processing {
 *     border-radius: 50%;
 *     filter: blur(0px);
 *     background-image: linear-gradient(333deg, #E142C0 26%, #FF8700 54%);
 *     width: 150Px;
 *     height: 150px;
 * }
 * 
 * .processing::after {
 *     content: "";
 *     position: absolute;
 *     background: rgba(100, 100, 100, 0.0);
 *     
 *     border-radius: 50%;
 *     background: linear-gradient(333deg, #E142C0 51%, #FF8700 54%);
 *     width: 150px;
 *     height: 150px;
 *     
 *     filter: blur(18px);
 *     animation: rotate spin-clouds 3s infinite linear;
 *     top: 0;
 * }
 * 
 * 
 * 
 * 
 * .playng {
 *     border-radius: 50%;
 *     filter: blur(0px);
 *     background-image: linear-gradient(148deg, #E142C0 26%, #FF8700 54%);
 *     width: 150Px;
 *     height: 150px;
 * 
 * }
 * 
 * .playng::after {
 *     content: "";
 *     position: absolute;
 *     background: rgba(100, 100, 100, 0.0);
 *     
 *     border-radius: 50%;
 *     background: linear-gradient(150deg, #E142C0 26%, #FF8700 54%);
 *     width: 150px;
 *     height: 150px;
 *     
 *     filter: blur(18px);
 *     animation: rotate spin-clouds 3s infinite linear;
 *     top: 0;
 * }
 * 
 * 
 * .box1 {
 *     inset: 40%;
 *     z-index: 99;
 *     position: absolute;
 *     background-image: url(../../images/circle1.svg);
 *     background-size: 99%;
 *     animation: ripple 0.2s infinite ease-in-out;
 *     animation-duration: 4s;
 *     transform: scale(1, 1);
 * }
 * 
 * .box2 {
 *     inset: 33%;
 *     z-index: 98;
 *     background-image: url(../../images/circle2.svg);
 *     animation-delay: 0.2s;
 *     position: absolute;
 *     background-size: 99%;
 *     animation: ripple 0.2s infinite ease-in-out;
 *     animation-duration: 4s;
 *     transform: scale(1, 1);
 * 
 * }
 * 
 * .box3 {
 *     inset: 26%;
 *     z-index: 97;
 *     background-image: url(../../images/circle3.svg);
 *     animation-delay: 0.4s;
 *     position: absolute;
 *     background-size: 99%;
 *     animation: ripple 0.2s infinite ease-in-out;
 *     animation-duration: 4s;
 *     transform: scale(1, 1);
 * 
 * }
 * 
 * .box4 {
 *     inset: 24%;
 *     z-index: 96;
 *     background-image: url(../../images/circle4.svg);
 *     animation-delay: 0.6s;
 *     position: absolute;
 *     background-size: 99%;
 *     animation: ripple 0.2s infinite ease-in-out;
 *     animation-duration: 4s;
 *     transform: scale(1, 1);
 * }
 * 
 * .box5 {
 *     inset: 18%;
 *     z-index: 95;
 *     background-image: url(../../images/circle5.svg);
 *     animation-delay: 0.8s;
 *     position: absolute;
 *     background-size: 99%;
 *     animation: ripple 0.2s infinite ease-in-out;
 *     animation-duration: 4s;
 *     transform: scale(1, 1);
 * }
 * 
 * .playng1 {
 *     width: 100px;
 *     height: 100px;
 *     inset: 38%;
 *     z-index: 99;
 *     position: absolute;
 *     background-image: url(../../images/circle1.svg);
 *     animation: ripple 0.2s infinite ease-in-out;
 *     animation-duration: 2s;
 *     transform: scale(1, 1);
 *     background-size: 100px;
 *     margin: 0 auto;
 *     top: 26px;
 *     left: 0;
 *     right: 0;
 *     bottom: 0;
 * }
 * 
 * .playng2 {
 *     width: 110px;
 *     height: 110px;
 *     inset: 30%;
 *     z-index: 98;
 *     position: absolute;
 *     background-image: url(../../images/circle2.svg);
 *     animation-delay: 0.4s;
 *     animation: ripple 0.2s infinite ease-in-out;
 *     animation-duration: 2s;
 *     transform: scale(1.1, 1.1);
 *     background-size: 110px;
 *     top: 21px;
 *     left: 0;
 *     right: 0;
 *     margin: 0 auto;
 * }
 * 
 * 
 * 
 * 
 * 
 *        
 *     }
 * 
 *     50% {
 *         transform: scale(1.3);
 *        
 *     }
 * 
 *     100% {
 *         transform: scale(1);
 *         
 *     }
 * }
 * 
 *     }
 * 
 *     50% {
 *         fill: white;
 *     }
 * 
 *     100% {
 *         fill: logo-color;
 *     }
 * }
 *
 * @format
 * @keyframes wobble { 0% { transform: scale(1);
 * @keyframes move-forever { 0% { transform: translate(-4px, 0); z-index: 9999; opacity: 1;
 * @keyframes rotate { 0% { background-position: 120% 150%;
 * @keyframes spin-clouds { 0% { transform: rotate(360deg);
 * @keyframes ripple { 0% { transform: scale(1);
 * @keyframes color-change { 0% { fill: logo-color;
 * @media only screen and (max-width: 767px) { .chat-bot p img { display: block;
 */

/** @format */

.full_screen {
  background-color: #131314;
  width: 100%;
  /* height: 100vh; */
}

.FullScreenView.chat-bot {
  background-color: #1e1f20;
  height: 695px;
  box-shadow: -25px 0px 25px #00000080;
  transition: 1.25s;
}

.smallScreenView.chat-bot {
  background-color: #1e1f20;
  height: 596px;
  /* width: 30%; */
  margin-left: 70%;
  box-shadow: -25px 0px 25px #00000080;
  transition: 1.25s;
  position: sticky;
  right: 0;
}

.FullScreenView .penny-tittle svg {
  margin: 21px 10rem;
}

.smallScreenView .penny-tittle svg {
  margin: 10px 30px;
  margin-top: 40px;
}

.FullScreenView .box_scroll.speak {
  display: block;
}

.smallScreenView .box_scroll.speak {
  display: none;
}

/* .option.speak {
  width: 170px;
  height: 170px;
}

.option.speak::before {
  width: 167px;
  height: 164px;
}

.option.speak::after {
  height: 170px;
} */

.option.speak .action-button {
  width: 83%;
  height: 41px;
  font-size: 14px;
  background: linear-gradient(90deg, #ff8700 -6%, #e346b4 44%) !important;
}
.option.speak .action-button a {
  text-decoration: none;
  color: #fff;
}
/* .option.speak .action-button::before {
    content: "";
    position: absolute;
    transform: translate(0px, -1px);
    z-index: -1;
    background: linear-gradient(325deg, #ff8700, #e142c0, #ff8700, #e142c0);
    background-size: 200% 200%;
    border-radius: 10px;
    width: 135px;
    height: 41px;
    animation: gradient 20s linear infinite;
} */

.option.speak h3 {
  font-size: 13px;
}

.option.speak p.desc {
  max-height: auto;
  height: auto;
  left: 0;
  justify-content: flex-start;
  font-size: 12px;
}

.options-container.speak {
  width: 100%;
  margin: 34px auto 0;
}

.chat-bot p {
  position: relative;
  display: flex;
  left: 26px;
  right: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  /* height: 97px; */
  top: 2px;
  color: #8d8d8d;
}

.demo {
  min-width: 150px;
  position: relative;
}

.circles {
  opacity: 0.3;
  border-radius: 50%;
}

/* .circle.processing.box {
      border-radius: 50%;
      position: absolute;
      z-index: 99;
      top: 0px;
      border-radius: 50%;
      background: linear-gradient(325deg, #FF8700 26%, #E142C0 54%);
      width: 150px;
      height: 150px;
      position: relative;
      filter: blur(0px);
      left: 0;
      right: 0;
  
  } */

/* .circle.processing.box::after {
      filter: blur(15px);
      width: 150px;
      height: 150px;
      animation: wobble 2s infinite;
      background: linear-gradient(325deg, #E142C0 26%, #FF8700 54%);
      transition: 1.2s;
      z-index: 0;
  } */

@keyframes wobble {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.gradient-box {
  position: relative;
  width: 0px;
  height: 50px;
  /* background-color: #1e1f20;
      border-radius: 50%; */
  z-index: 99;
  /* box-shadow: 0px 0px 14px 2px rgb(255 55 55); */
}

.gradient-box::before {
  content: "";
  position: absolute;
  background-image: url(../../images/box_img.png);
  width: 50px;
  height: 50px;
  background-size: cover;
  cursor: pointer;
}

.chat-bot p img {
  width: 14px;
  /* display: none; */
  position: relative;
  z-index: 2;
  cursor: pointer;
  transform: rotate(85deg);
  margin: 18px;
}

.chat-bot p img.FullScreenView {
  width: 16px;
}

.chat-bot img.bg_img {
  width: 55px;
  height: 55px;
}

.FullScreenView .content-tittle {
  width: 100%;
  padding: 0 30px 4px 10rem;
  position: absolute;
  top: 53px;
}

.smallScreenView .content-tittle {
  width: 100%;
  padding: 0 30px 50px 2rem;
  position: relative;
  top: 0px;
}

.content-tittle h2 {
  font-size: 24px;
  background: linear-gradient(90deg, #ff8700 3%, #e142c0 9%) text #ffffff;
    -webkit-text-fill-color: transparent;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  font-weight: 400;
  margin: 0;
  background: linear-gradient(
    to right,
    #ff8700 10%,
    #e142c0 20%,
    #ff8700 40%,
    #e142c0 60%,
    #ff8700 80%,
    #e142c0 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 30s ease-in-out infinite alternate;
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.content-tittle p {
  justify-content: flex-start;
  position: relative;
  left: 0;
  height: auto;
  color: #8d8d8d;
  font-size: 24px;
  margin-top: 2px;
  top: 0;
}

.circle {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  z-index: 9;
  background-image: linear-gradient(325deg, #ff8700 26%, #e142c0 54%);
}

/* @keyframes move-forever {
    0% {
      transform: translate(-4px, 0);
      z-index: 9999;
      opacity: 1;
    }
  
    20% {
      z-index: -9999;
      opacity: 0;
    }
  
    50% {
      transform: translate(3px, 0);
    }
  
    100% {
      transform: translate(-4px, 0);
    }
  }*/

.circle::after {
  content: "";
  position: absolute;
  transform: translate(0px, -1px);
  z-index: 0;
  background-image: linear-gradient(325deg, #ff8700, #e142c0);
  background: linear-gradient(325deg, #ff8700, #e142c0);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  filter: blur(5px);
  z-index: 1;
}

.bottom-icon {
  width: 97%;
    padding-bottom: 0px;
    margin: 20px auto;
    height: auto;
    position: fixed;
    bottom: 0px;
}
.smallScreenView {
  position: relative;
}
.smallScreenView .bottom-icon {
  width: 30%;
    padding-bottom: 0px;
    margin: 20px auto;
    height: auto;
    position: fixed;
    bottom: 0px;
}

.bottom-icon button {
  background-image: url(../../images/box_img.png) !important;
  width: 50px;
  height: 50px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .FullScreenView .chat-bot-header {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      transition: 2s;
  }
  .smallScreenView .chat-bot-header {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      transition: 2s;
  } */

.bottom-icon button img {
  width: 12px;
  height: 12px;
}

/* @media only screen and (max-width: 1199px)  {
      .chat-bot {
          background-color: #1e1f20;
          height: 100vh;
          width: 40%;
          margin-left: 60%;
          box-shadow: -25px 0px 25px #00000080;
      }
  }
  
  
  @media only screen and (max-width: 992px)  {
      .chat-bot {
          background-color: #1e1f20;
          height: 100vh;
          width: 46%;
          margin-left: 54%;
          box-shadow: -25px 0px 25px #00000080;
      }
  } */

@media only screen and (max-device-width: 1366px) {
  .bottom-icon {
    padding-bottom: 0px;
    padding-top: 0;
  }

  .content-tittle {
    padding: 0 26px 30px;
  }

  .action-button {
    width: 83%;
  }
  .options-container.speak {
    width: 80%;
  }
  .option {
    width: 269px;
    height: 265px;
  }
  .option::before {
    width: 212px;
    height: 260px;
  }
  .option::after {
    width: 100%;
    height: 265px;
  }
  .chat-modal .options-container.speak {
    width: 88%;
  }
}

@media only screen and (max-width: 767px) {
  .chat-bot p img {
    display: block;
  }

  .bottom-icon {
    width: 95%;
  }
  .smallScreenView .bottom-icon {
    width: 91%;
  }

  /* .chat-bot {
          background-color: #1e1f20;
          height: 100vh;
          width: 57%;
          margin-left: 43%;
          box-shadow: -25px 0px 25px #00000080;
      } */
  .smallScreenView.chat-bot {
    margin-left: 0;
  }

  .content-tittle h2 {
    background: linear-gradient(90deg, #ff8700 -10%, #e142c0 32%) text #ffffff;
  }

  .FullScreenView .box_scroll.speak {
    display: none;
  }
}

/* .planet_container {
      display: flex;
      justify-content: center;
      margin-top: 40px;
  }
  
  .planet {
      background-size: 200%;
      border-left: solid 1px black;
      border-radius: 50%;
      box-shadow: 5px 0 20px 10px #040615 inset;
      height: 420px;
      width: 420px;
  } */

@keyframes rotate {
  0% {
    background-position: 120% 150%;
  }

  100% {
    background-position: -80% -100%;
  }
}

@keyframes spin-clouds {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(-366deg);
  }
}

/* .processing {
    border-radius: 50%;
    filter: blur(0px);
    background-image: linear-gradient(333deg, #e142c0 26%, #ff8700 54%);
    width: 150px;
    height: 150px;
  }
  .processing::after {
    content: "";
    position: absolute;
    background: rgba(100, 100, 100, 0);
    border-radius: 50%;
    background: linear-gradient(333deg, #e142c0 51%, #ff8700 54%);
    width: 150px;
    height: 150px;
    border-top: 1px solid rgba(100, 100, 100, 1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;
    animation: ripple 0.2s infinite ease-in-out;
      animation-duration: 2s;
      transform: scale(1.2, 1.2);
    filter: blur(18px);
    animation: rotate spin-clouds 3s infinite linear;
    top: 0;
  } */

/* .box {
      position: absolute;
      background: rgba(100, 100, 100, 0.0);
      background: #fff;
      border-radius: 50%;
      border-top: 1px solid rgba(100, 100, 100, 1);
      box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;
      animation: ripple 0.2s infinite ease-in-out;
      animation-duration: 2s;
      transform: scale(1.2, 1.2);
  } */

/* .playng {
    border-radius: 50%;
    filter: blur(0px);
    background-image: linear-gradient(148deg, #e142c0 26%, #ff8700 54%);
    width: 150px;
    height: 150px;
  }
  
  .playng::after {
    content: "";
    position: absolute;
    background: rgba(100, 100, 100, 0);
    border-radius: 50%;
    background: linear-gradient(150deg, #e142c0 26%, #ff8700 54%);
    width: 150px;
    height: 150px;
    filter: blur(18px);
    animation: rotate spin-clouds 3s infinite linear;
    top: 0;
  } */

.box1 {
  inset: 40%;
  z-index: 99;
  position: absolute;
  background-image: url(../../images/circle1.svg);
  background-size: 99%;
  animation: ripple 0.2s infinite ease-in-out;
  animation-duration: 4s;
  transform: scale(1, 1);
}

.box2 {
  inset: 33%;
  z-index: 98;
  background-image: url(../../images/circle2.svg);
  animation-delay: 0.2s;
  position: absolute;
  background-size: 99%;
  animation: ripple 0.2s infinite ease-in-out;
  animation-duration: 4s;
  transform: scale(1, 1);
}

.box3 {
  inset: 26%;
  z-index: 97;
  background-image: url(../../images/circle3.svg);
  animation-delay: 0.4s;
  position: absolute;
  background-size: 99%;
  animation: ripple 0.2s infinite ease-in-out;
  animation-duration: 4s;
  transform: scale(1, 1);
}

.box4 {
  inset: 24%;
  z-index: 96;
  background-image: url(../../images/circle4.svg);
  animation-delay: 0.6s;
  position: absolute;
  background-size: 99%;
  animation: ripple 0.2s infinite ease-in-out;
  animation-duration: 4s;
  transform: scale(1, 1);
}

.box5 {
  inset: 18%;
  z-index: 95;
  background-image: url(../../images/circle5.svg);
  animation-delay: 0.8s;
  position: absolute;
  background-size: 99%;
  animation: ripple 0.2s infinite ease-in-out;
  animation-duration: 4s;
  transform: scale(1, 1);
}

.playng1 {
  width: 100px;
  height: 100px;
  inset: 38%;
  z-index: 99;
  position: absolute;
  background-image: url(../../images/circle1.svg);
  animation: ripple 0.2s infinite ease-in-out;
  animation-duration: 2s;
  transform: scale(1, 1);
  background-size: 100px;
  margin: 0 auto;
  top: 26px;
  left: 0;
  right: 0;
  bottom: 0;
}

.playng2 {
  width: 110px;
  height: 110px;
  inset: 30%;
  z-index: 98;
  position: absolute;
  background-image: url(../../images/circle2.svg);
  animation-delay: 0.4s;
  animation: ripple 0.2s infinite ease-in-out;
  animation-duration: 2s;
  transform: scale(1.1, 1.1);
  background-size: 110px;
  top: 21px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* svg {
      fill: #fff;
      width: 100%;
      animation: color-change duration infinite ease-in-out;
  } */

@keyframes ripple {
  0% {
    transform: scale(1);
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px; */
  }

  50% {
    transform: scale(1.3);
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 30px 20px -0px; */
  }

  100% {
    transform: scale(1);
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px; */
  }
}

@keyframes color-change {
  0% {
    fill: logo-color;
  }

  50% {
    fill: white;
  }

  100% {
    fill: logo-color;
  }
}

.circle2,
.circle3 {
  border-radius: 50%;
  width: 175px;
  height: 175px;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(325deg, #ff8700, #e142c0, #ff8700, #e142c0);
  /* background: black; */
  background-size: 200% 200%;
  /* animation: gradient 15s ease normal infinite; */
}

.idolcircle {
  border-radius: 50%;
  width: 175px;
  height: 175px;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(145deg, #a3a3a3, #363636, #a3a3a3, #363636);
  /* background: black; */
  background-size: 200% 200%;
  /* animation: gradient 15s ease normal infinite; */
}

.idolcircle::before {
  content: "";
  position: absolute;
  transform: translate(0px, 1px);
  z-index: -1;
  background: linear-gradient(145deg, #a3a3a3, #363636, #a3a3a3, #363636);
  background-size: 400% 400%;
  border-radius: 50%;
  width: 175px;
  height: 175px;
  filter: blur(10px);
  animation: gradient 40s linear infinite;
}

.circle2::before,
.circle3::before {
  content: "";
  position: absolute;
  transform: translate(0px, -1px);
  z-index: -1;
  background: linear-gradient(325deg, #ff8700, #e142c0, #ff8700, #e142c0);
  background-size: 200% 200%;
  border-radius: 50%;
  width: 175px;
  height: 175px;
  filter: blur(10px);
  animation: gradient 20s linear infinite;
}

.circle2.listening {
  border-radius: 50%;
  width: 175px;
  height: 175px;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(110, 110, 110);
  background: linear-gradient(325deg, #ff8700, #e142c0, #ff8700, #e142c0);
  /* background-color: white; */
  /* background: black; */
  background-size: 200% 200%;
  /* animation: gradient 15s ease normal infinite; */
  animation: scaling 5s ease-in-out alternate infinite;
}

.circle2.listening::before {
  content: "";
  position: absolute;
  transform: translate(0px, 0px);
  z-index: -1;
  background: linear-gradient(325deg, #ff8700, #e142c0, #ff8700, #e142c0);
  background-size: 200% 200%;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  filter: blur(18px);
  animation: gradient 20s linear infinite;
}

.circle2 .box12 {
  inset: 90%;
  z-index: 99;
  background-color: white;
  border-radius: 100%;
  background: linear-gradient(-30deg, #ff8700, #e142c0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 125px;
  height: 125px;
}

.circle2 .box22 {
  inset: 90%;
  z-index: 98;
  background-color: white;
  border-radius: 100%;
  background: linear-gradient(75deg, #ff8700, #e142c0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

.circle3 .box02 {
  inset: 90%;
  z-index: 100;
  background-color: white;
  border-radius: 100%;
  background: linear-gradient(-300deg, #ff8700, #e142c0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
}

.circle3 .box12 {
  inset: 90%;
  z-index: 99;
  background-color: white;
  border-radius: 100%;
  background: linear-gradient(-30deg, #ff8700, #e142c0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}

.circle3 .box22 {
  inset: 90%;
  z-index: 98;
  background-color: white;
  border-radius: 100%;
  background: linear-gradient(75deg, #ff8700, #e142c0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 75px;
}

.circle3 .box32 {
  inset: 26%;
  z-index: 97;
  background-color: white;
  border-radius: 100%;
  background: linear-gradient(-150deg, #ff8700, #e142c0);
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.circle3 .box42 {
  inset: 24%;
  z-index: 96;
  background-color: white;
  border-radius: 100%;
  background: linear-gradient(20deg, #ff8700, #e142c0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 125px;
  height: 125px;
}

.circle3 .box52 {
  inset: 18%;
  z-index: 95;
  background-color: white;
  border-radius: 100%;
  background: linear-gradient(75deg, #ff8700, #e142c0);
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

@keyframes scaling {
  0% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 100%;
  }

  50% {
    background-position: 200% 200%;
  }

  75% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}
